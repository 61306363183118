import settings from '@/settings'

/**
 * 環境
 */
class Environment {
  /**
   * 學校環境資訊
   */
  getSchoolClimatology(schoolId, type, from, to) {
    return axios.get(
      settings.api.fullPath + `/ems/climatology/school/${schoolId}`,
      {
        params: {
          type: type,
          from: from,
          to: to
        }
      }
    )
  }

  /**
   * 教室環境資訊
   */
  getClassClimatology(classId, from, to) {
    return axios.get(
      settings.api.fullPath + `/ems/climatology/class/${classId}`,
      {
        params: {
          from: from,
          to: to
        }
      }
    )
  }
}

var environment = new Environment()
export default environment
