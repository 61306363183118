<template>
  <v-container fluid class="py-0">
    <v-form>
      <v-row align="center">
        <v-col cols="12" sm="6" md="8" v-if="!noTarget">
          <v-btn
            @click="selectTarget"
            elevation="0"
            class="accent--text font-weight-bold"
            color="ems-aquamarine"
          >
            選擇查詢目標
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dates"
                label="選擇時間區間"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                required
                :rules="[
                  (v) => !!v || '選擇時間區間',
                  ...(datePickerType !== 'perHour'
                    ? (v) => v.indexOf('~') > -1 || '請選擇時間結束日期'
                    : [])
                ]"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="datesRange"
              no-title
              range
              scrollable
              color="primary"
              :max="moment().format('YYYY-MM-DD')"
              :min="
                moment()
                  .subtract(3, 'years')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
              "
              @input="pickDate($event)"
              @click:year="saveYear($event)"
              @click:month="saveMonth($event)"
              :type="datePickerTypeData === 'perDay' ? 'month' : 'date'"
              reactive
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="targetMenu"
      max-width="70%"
      transition="dialog-bottom-transition"
    >
      <!--  frosted-glass -->
      <div class="dialog-content">
        <v-container fluid class="">
          <v-row align="center" class="dialog-title pa-3">
            <v-col
              cols="12"
              class="pa-0 d-flex align-center justify-space-between"
            >
              <span class="accent--text font-weight-bold">
                選擇查詢目標 (請至少選擇一個）
              </span>
              <v-btn icon text @click="targetMenu = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="12" class="pa-0">
              <div class="ems-blue-grey-2--text subtitle-2">
                查詢目標需與查詢項目的計算單位相符，所以請先選定一個對象屬性
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="px-0">
              <v-select
                dense
                :items="typesOpt"
                v-model="type"
                background-color="white"
                elevation="0"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                label="選擇對象屬性"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <div class="targetMenu-wrap">
          <vue-scroll>
            <vue-scroll ref="vsList">
              <v-list-item
                v-for="(item, index) in lists"
                :key="'list-' + item.id + '-' + index"
                :id="item.id"
              >
                <v-list-item-content three-line>
                  <v-list-item-title
                    class="d-flex align-center ems-blue-grey-2--text"
                  >
                    <v-icon class="mr-1" :color="chartColors[index]">
                      mdi-checkbox-blank
                    </v-icon>
                    目標 {{ index + 1 }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-row dense>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        v-for="(select, selectIndex) in item.selectors"
                        :key="selectIndex"
                      >
                        <v-select
                          dense
                          :value="item.data[select.key] || null"
                          :items="select.items"
                          background-color="white"
                          elevation="0"
                          item-text="name"
                          item-value="id"
                          return-object
                          outlined
                          hide-details
                          @input="selectType($event, item, select.key)"
                          class="pt-2"
                          :label="select.placeholder"
                        >
                          <!-- <template v-slot:item="{ item }">
                            <div class="py-1">
                              <div>{{ item.name }}</div>
                              <v-sheet
                                v-if="item.category === 'METER'"
                                width="fit-content"
                                class="text-caption px-1"
                                color="ems-light-grey"
                              >
                                {{ item.serialNumber || 'null' }} |
                                {{ item.modbusId || 'null' }}
                              </v-sheet>
                            </div>
                          </template> -->
                        </v-select>
                      </v-col>
                      <v-col cols="auto" v-if="!item.unRemove">
                        <v-btn icon text @click="removeList(item)">
                          <v-icon> mdi-close </v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="type" class="pt-3" id="addBtn">
                <v-btn
                  outlined
                  color="primary"
                  :disabled="!type"
                  @click="addList"
                >
                  <v-icon>mdi-plus</v-icon>
                  新增目標
                </v-btn>
              </v-list-item>
            </vue-scroll>
          </vue-scroll>
        </div>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                elevation="0"
                color="primary"
                class="px-12"
                @click="updateValue"
              >
                確認
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ChartOptions',
  props: {
    // tagId: {
    //   type: String,
    //   required: true
    // },
    noTarget: {
      // no-target
      type: Boolean,
      default: () => false
    },
    types: {
      type: Array,
      default: () => ['school', 'class', 'group']
    },
    datePickerType: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: () => ({
        list: [],
        timestamp: []
      })
    },
    schoolMeters: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    // targetMenu: true,
    targetMenu: false,
    dateMenu: false,
    datesRange: [],
    // datesRange: ['', ''],
    // datesRange: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    lists: [],
    type: null,
    editOpt: null,
    datePickerTypeData: null
  }),
  watch: {
    value() {
      if (this.value) {
        this.lists = this.value.list || []
        this.datesRange = this.value.timestamp || []
      } else {
        this.type = null
        this.lists = []
        this.datesRange = []
      }
    },
    // vsList
    editOpt() {
      const listId = `#list-${this.editOpt || 0}`
      this.$refs['vsList'].scrollIntoView(listId, 500)
    },
    type() {
      // clear all
      this.lists = []
      if (this.type === 'school' || this.type === 'schoolMeter') {
        this.selectType(this.type, {}, 'type')
      }
    },
    dateMenu(val) {
      if (val && this.datePickerType === 'perHour' && this.datesRange.length) {
        this.datesRange = []
      }
      if (this.datePickerType === 'perMonth') {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
      }
    }
  },
  computed: {
    ...mapGetters({
      chartColors: 'config/chartColors',
      buildings: 'building/buildings',
      groups: 'groups/groups'
    }),
    typesOpt() {
      return [
        {
          id: 'school',
          name: '學校'
        },
        {
          id: 'schoolMeter',
          name: '學校'
        },
        {
          id: 'class',
          name: '教室'
        },
        {
          id: 'classMeter',
          name: '教室'
        },
        {
          id: 'group',
          name: '群組'
        }
      ].filter(({ id }) => this.types.indexOf(id) > -1)
    },
    dates() {
      if (this.datesRange.length === 0) return ''
      if (
        this.datePickerType === 'perHour' &&
        this.datesRange[0] === this.datesRange[1]
      ) {
        return this.datesRange[0]
      }
      const dates = this.datesRange
        .slice()
        .sort(
          (a, b) =>
            parseFloat(moment(a).format('x'), 10) -
            parseFloat(moment(b).format('x'), 10)
        )
      return dates.join('~')
    },
    idsList() {
      return this.lists
        .filter((item) => item.value && item.value !== '')
        .map(({ value }) => value)
    }
  },
  methods: {
    ...mapActions({
      getAllBuildings: 'building/getAllBuildings',
      getGroups: 'groups/getGroups'
    }),
    async addList() {
      const id = 'new-' + this.lists.length
      const item = {
        id,
        selectors: [],
        data: {
          type: this.type
        },
        name: '',
        value: '',
        deviceList: []
      }
      this.lists.push(item)
      this.selectType(this.type, item, 'type')
      await this.$nextTick()
      this.$refs['vsList'].scrollIntoView('#addBtn', 500)
    },
    removeList(item) {
      this.lists = this.lists.filter((d) => d.id !== item.id)
    },
    selectType(value, item, type) {
      let selectors = item.selectors || []
      switch (type) {
        case 'type':
          if (value === 'group') {
            const group = this.groups
              .filter(({ type }) => type === 'METER')
              .filter((d) => this.idsList.indexOf(d.id) < 0)
            selectors = [
              {
                name: 'group',
                placeholder: '選擇群組',
                items: group,
                key: 'group'
              }
            ]
            item.data = {
              type: this.type,
              group: ''
            }
          }
          if (value === 'class' || value === 'classMeter') {
            selectors = [
              {
                name: 'building',
                placeholder: '選擇大樓',
                items: this.buildings,
                key: 'building'
              }
            ]
            item.data = {
              type: this.type,
              building: '',
              floor: '',
              class: ''
            }
          }
          if (value === 'school' || value === 'schoolMeter') {
            // const item = {
            //   id: this.tagId,
            //   unRemove: true,
            //   selectors: [
            //     {
            //       name: 'school',
            //       placeholder: '學校',
            //       items: ['學校'],
            //       key: 'school'
            //     }
            //   ],
            //   data: {
            //     type: this.type,
            //     shcool: this.tagId
            //   },
            //   name: '學校',
            //   value: this.tagId
            // }
            // this.lists.push(item)
            const meters = (this.schoolMeters || []).filter(
              (d) => this.idsList.indexOf(d.id) < 0
            )
            selectors = [
              {
                name: 'meter',
                placeholder: '選擇電表',
                items: meters,
                key: 'meter'
              }
            ]
            item.data = {
              type: this.type
            }
          }
          break
        case 'meter':
          item.name = '學校'
          item.value = value.id
          item.data.meter = value
          break
        case 'building':
          if (selectors.length === 3) {
            // 變更建築移除教室選項
            selectors.splice(2, 1)
          }
          if (selectors.length === 2) {
            // 變更建築更新樓層
            selectors[1].items = value.floorList || []
          }
          if (selectors.length <= 1) {
            selectors.push({
              name: 'floor',
              placeholder: '選擇樓層',
              items: value.floorList || [],
              key: 'floor'
            })
          }
          item.data.building = value
          item.data.floor = ''
          item.data.class = null
          item.name = ''
          item.value = ''
          item.deviceList = []
          break
        case 'floor':
          {
            item.data.floor = value
            item.data.class = null
            item.name = ''
            item.value = ''
            item.deviceList = []
            const items = (value.classList || []).filter(
              (d) => this.idsList.indexOf(d.id) < 0
            )
            selectors = selectors.filter((d) => d.name !== 'class')
            selectors.push({
              name: 'class',
              placeholder: '選擇班級',
              items,
              key: 'class'
            })
          }
          break
        case 'group':
          item.data.group = value
          item.name = value.name
          item.value = value.id
          item.deviceList = value.deviceList
          break
        case 'class':
          item.data.class = value
          item.name = `${item.data.building.name}／${item.data.floor.name}／${value.name}`
          item.value = value.id
          item.deviceList = value.deviceList
          break
        default:
          break
      }
      item.selectors = selectors
    },
    selectTarget() {
      this.targetMenu = true
    },
    addToOptions(item) {
      if (item.data.class || item.data.group) {
        item.add = true
      }
    },
    updateValue() {
      if (this.datesRange.length === 2) {
        this.datesRange = this.dates.split('~')
      }
      let emitDateRange = []
      if (this.datePickerType === 'perHour' && this.datesRange.length === 1)
        emitDateRange = this.datesRange.concat([this.datesRange[0]])
      else emitDateRange = this.datesRange
      this.$emit('input', {
        list: this.lists,
        timestamp: emitDateRange
      })
      this.targetMenu = false
    },
    pickDate(val) {
      if (this.datePickerType === 'perHour' || val.length >= 2) {
        this.dateMenu = false
      }
      this.updateValue()
    },
    saveMonth(val) {
      if (this.datePickerType === 'perDay') {
        this.datesRange = [
          moment(val, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
          moment(val, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
        ]
        this.dateMenu = false
        console.log(this.datesRange)
        this.updateValue()
      }
    },
    saveYear(val) {
      if (this.datePickerType === 'perMonth') {
        this.datesRange = [
          moment(val, 'YYYY').startOf('year').format('YYYY-MM-DD'),
          moment(val, 'YYYY').endOf('year').format('YYYY-MM-DD')
        ]
        this.dateMenu = false
        this.updateValue()
      }
    }
  },
  async mounted() {
    this.datePickerTypeData = this.datePickerType
    // if (!this.noTarget) {
    //   if (
    //     !this.buildings ||
    //     (this.buildings[0] && this.buildings[0].schoolId !== this.tagId)
    //   ) {
    //     await this.getAllBuildings(this.tagId)
    //     await this.getGroups(this.tagId)
    //   }
    // }
    if (this.value) {
      this.lists = this.value.list || []
      this.datesRange = this.value.timestamp
    }
    this.updateValue()
  }
}
</script>

<style scoped lang="scss">
.dialog-content {
  background: rgb(255 255 255 / 100%);
  overflow: hidden;
  height: 90%;
  min-width: 300px;
  // padding: .5rem;
}
.targetMenu-wrap {
  height: 320px;
  max-height: 410px;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  .targetList-wrap {
    max-height: 410px;
  }
}
</style>
