<template>
  <div class="pa-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">電表分析</h3>
          <v-card class="mx-auto frosted-glass" elevation="0">
            <v-tabs
              :value="meterOpt"
              @change="handleTabChange"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in meterTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="meterOpt">
              <v-tab-item v-for="(item, index) in meterTabs" :key="item.id">
                <ChartOptions
                  v-if="buildings"
                  :tagId="tagId"
                  :types="item.optionTypes"
                  :datePickerType="
                    index === 1 ? 'perDay' : index === 2 ? 'perMonth' : null
                  "
                  :schoolMeters="
                    schoolMeterData.filter((m) => m.type !== 'SOLARMETER')
                  "
                  v-model="meterOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="meterChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <template v-if="meterOption && meterChartData">
                    <v-progress-linear
                      class="mb-2"
                      v-if="requests.meter"
                      :indeterminate="true"
                    />
                  </template>
                  <Echart
                    :data="meterChartData"
                    :no-data="chartStatus(meterOption, meterChart).status"
                    :loading="meterChart.loading"
                    :noDataText="chartStatus(meterOption, meterChart).text"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">發電電表分析</h3>
          <v-card class="mx-auto frosted-glass" elevation="0">
            <v-tabs
              :value="generatePowerOpt"
              @change="handleGenerateTabChange"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in generatePowerTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="generatePowerOpt">
              <v-tab-item
                v-for="(item, index) in generatePowerTabs"
                :key="item.id"
              >
                <ChartOptions
                  v-if="buildings"
                  :tagId="tagId"
                  :types="item.optionTypes"
                  :datePickerType="
                    index === 1 ? 'perDay' : index === 2 ? 'perMonth' : null
                  "
                  :schoolMeters="
                    schoolMeterData.filter((m) => m.type === 'SOLARMETER')
                  "
                  v-model="generatePowerOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="generatePowerChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <template
                    v-if="generatePowerOption && generatePowerChartData"
                  >
                    <v-progress-linear
                      class="mb-2"
                      v-if="requests.generatePower"
                      :indeterminate="true"
                    />
                  </template>
                  <Echart
                    :data="generatePowerChartData"
                    :no-data="
                      chartStatus(generatePowerOption, generatePowerChart)
                        .status
                    "
                    :loading="generatePowerChart.loading"
                    :noDataText="
                      chartStatus(generatePowerOption, generatePowerChart).text
                    "
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">冷氣分析</h3>
          <v-card class="mx-auto frosted-glass" elevation="0">
            <v-tabs
              v-model="ACOpt"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in ACTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="ACOpt">
              <v-tab-item v-for="item in ACTabs" :key="item.id">
                <ChartOptions
                  v-if="buildings"
                  :tagId="tagId"
                  :types="item.optionTypes"
                  v-model="ACOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="ACChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <template v-if="ACOption && ACChartData">
                    <v-progress-linear
                      class="mb-2"
                      v-if="requests.ac"
                      :indeterminate="true"
                    />
                  </template>
                  <Echart
                    :data="ACChartData"
                    :no-data="chartStatus(ACOption, ACChart).status"
                    :loading="ACChart.loading"
                    :noDataText="chartStatus(ACOption, ACChart).text"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">環境分析</h3>
          <v-card class="mx-auto frosted-glass" width="100%" elevation="0">
            <v-tabs
              v-model="envOpt"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in envTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="envOpt">
              <v-tab-item v-for="item in envTabs" :key="item.id">
                <ChartOptions
                  v-if="buildings"
                  :tagId="tagId"
                  :types="item.optionTypes"
                  :noTarget="item.noTarget"
                  v-model="envOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="envChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <template v-if="envOption && envChartData">
                    <v-progress-linear
                      class="mb-2"
                      v-if="requests.env"
                      :indeterminate="true"
                    />
                  </template>
                  <Echart
                    :loading="envChart.loading"
                    :no-data="chartStatus(envOption, envChart).status"
                    :noDataText="chartStatus(envOption, envChart).text"
                    :data="envChartData"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AC from '@/api/ems/AC'
import Meter from '@/api/ems/Meter'
import Environment from '@/api/Environment'
import Echart from '@/components/Echart'
import ChartOptions from '@/components/ChartOptions'
import makeChart from '@/vendor/chart'
import { mapGetters, mapActions } from 'vuex'
// import fileDownload from 'js-file-download'

export default {
  components: {
    Echart,
    ChartOptions
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      schoolMeterData: [],
      ClassACData: null,
      meterOpt: 0,
      meterTabs: [
        {
          label: '小時用電量',
          id: 'HOUR_ENERGY',
          types: ['hourEnergy'],
          labels: [],
          optionTypes: ['schoolMeter', 'classMeter', 'group']
        },
        {
          label: '日用電量',
          id: 'DAY_ENERGY',
          types: ['dayEnergy'],
          labels: [],
          optionTypes: ['schoolMeter', 'classMeter', 'group']
        },
        {
          label: '月用電量',
          id: 'MONTH_ENERGY',
          types: ['monthEnergy'],
          labels: [],
          optionTypes: ['schoolMeter', 'classMeter', 'group']
        },
        {
          label: '需量',
          id: 'DEMAND',
          types: ['demand'],
          labels: [],
          optionTypes: ['schoolMeter']
        },
        {
          label: '功率',
          id: 'POWER',
          types: ['power', 'power'],
          labels: ['功率', '功率'],
          optionTypes: ['schoolMeter', 'classMeter']
        },
        {
          label: '電流',
          id: 'CURRENT',
          types: ['current', 'currentR', 'currentS', 'currentT'],
          labels: ['電流', 'R相', 'S相', 'T相'],
          optionTypes: ['schoolMeter', 'classMeter']
        },
        {
          label: '電壓',
          id: 'VOLTAGE',
          types: ['voltage', 'voltageR', 'voltageS', 'voltageT'],
          labels: ['電壓', 'R相', 'S相', 'T相'],
          optionTypes: ['schoolMeter', 'classMeter']
        }
      ],
      generatePowerOpt: 0,
      generatePowerTabs: [
        {
          label: '逐時發電量',
          id: 'HOUR_ENERGY',
          types: ['hourEnergy'],
          labels: [],
          optionTypes: ['schoolMeter']
        },
        {
          label: '逐日發電量',
          id: 'DAY_ENERGY',
          types: ['dayEnergy'],
          labels: [],
          optionTypes: ['schoolMeter']
        },
        {
          label: '逐月發電量',
          id: 'MONTH_ENERGY',
          types: ['monthEnergy'],
          labels: [],
          optionTypes: ['schoolMeter']
        },
        {
          label: '發電功率',
          id: 'POWER',
          types: ['power', 'power'],
          labels: ['功率', '功率'],
          optionTypes: ['schoolMeter']
        }
      ],
      ACOpt: 0,
      ACTabs: [
        {
          label: '啟停',
          id: 'STATUS',
          types: ['status'],
          labels: [],
          optionTypes: ['class']
        },
        {
          label: '運轉模式',
          id: 'MODE',
          types: ['mode'],
          labels: [],
          optionTypes: ['class']
        },
        {
          label: '設定溫度',
          id: 'TEMPERATURE',
          types: ['temperature'],
          labels: [],
          optionTypes: ['class']
        }
      ],
      envOpt: 0,
      envTabs: [
        {
          label: '室內溫度',
          id: 'roomTemperature',
          types: ['roomTemperature'],
          labels: [],
          optionTypes: ['class']
        },
        {
          label: '室外溫度',
          id: 'temperature',
          types: ['temperature'],
          labels: [],
          optionTypes: [],
          noTarget: true
        },
        {
          label: '室外濕度',
          id: 'humidity',
          types: ['humidity'],
          labels: [],
          optionTypes: [],
          noTarget: true
        },
        {
          label: '室外風速',
          id: 'windSpeed',
          types: ['windSpeed'],
          labels: [],
          optionTypes: [],
          noTarget: true
        }
      ],
      meterOption: null,
      generatePowerOption: null,
      ACOption: null,
      envOption: null,
      meterChart: {
        request: {},
        loading: false,
        data: null
      },
      generatePowerChart: {
        request: {},
        loading: false,
        data: null
      },
      ACChart: {
        request: {},
        loading: false,
        data: null
      },
      envChart: {
        request: {},
        loading: false,
        data: null
      },
      loadingChart: false,
      requests: {
        meter: false,
        generatePower: null,
        ac: false,
        env: false
      }
    }
  },
  watch: {
    // meterOpt() {
    //   this.meterOption = null
    //   this.meterChart = {
    //     request: {},
    //     loading: false,
    //     data: null
    //   }
    // },
    ACOpt() {
      this.ACOption = null
      this.ACChart = {
        request: {},
        loading: false,
        data: null
      }
    },
    envOpt() {
      this.envOption = null
      this.envChart = {
        request: {},
        loading: false,
        data: null
      }
    },
    meterOption() {
      if (!this.meterOption) return
      if (
        this.meterOption.timestamp[0] &&
        this.meterOption.timestamp[1] &&
        this.meterOption.list.length
      ) {
        this.getMeterHistory()
      }
    },
    generatePowerOption() {
      if (!this.generatePowerOption) return
      if (
        this.generatePowerOption.timestamp[0] &&
        this.generatePowerOption.timestamp[1] &&
        this.generatePowerOption.list.length
      ) {
        this.getGeneratePowerHistory()
      }
    },
    ACOption() {
      if (!this.ACOption) return
      if (
        this.ACOption.timestamp[0] &&
        this.ACOption.timestamp[1] &&
        this.ACOption.list.length
      ) {
        this.getACHistory()
      }
    },
    envOption() {
      if (!this.envOption) return
      if (!this.envOption.list || !Array.isArray(this.envOption.list)) return
      // 確認選取時間區間
      if (!this.envOption.timestamp[0] || !this.envOption.timestamp[1]) return

      // envOpt 0: 班級室內溫度 1:室外溫度 2:室外濕度 3:室外風速
      if ([1, 2, 3].includes(this.envOpt)) {
        this.envOption.list = [
          {
            name: '學校',
            value: this.tagId,
            data: {
              type: 'school',
              shcool: this.tagId
            }
          }
        ]
      }
      this.getEnvHistory()
    }
  },
  computed: {
    ...mapGetters({
      chartColors: 'config/chartColors',
      buildings: 'building/buildings'
    }),
    meterChartData() {
      if (!this.meterChart.data) return null
      if (this.meterChart.data.length === 0) return null
      if (this.meterOption && this.meterOption.list.length === 0) return null
      const Tabtype = `${this.meterTabs[this.meterOpt].types[0]}`
      const tabTypes = this.meterTabs[this.meterOpt].types
      const meterType = this.meterOption.list[0].data.type
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      yAxis.push(makeChart.yAxis(Tabtype))
      xAxis.push(makeChart.xAxis(Tabtype))
      const dataset = []
      this.meterChart.data.forEach((d, index) => {
        tabTypes.forEach((tab, tabIndex) => {
          // color.push(d.color)
          const label = this.meterTabs[this.meterOpt].labels[tabIndex]
          const key = `${tab}List`
          series.push({
            ...makeChart.series(this.meterTabs[this.meterOpt].types[0]),
            name: `${d.name}${label ? '_' + label : ''}`,
            datasetIndex: dataset.length // 對應 dataset
          })
          dataset.push({
            dimensions: ['timestamp', 'value'],
            source: d.data ? d.data[key] || [] : []
          })
        })
      })
      const chartOpt = makeChart.data(Tabtype)
      return {
        ...chartOpt,
        // color,
        xAxis,
        yAxis,
        dataset,
        series
      }
    },
    generatePowerChartData() {
      if (!this.generatePowerChart.data) {
        return null
      }
      if (this.generatePowerChart.data.length === 0) {
        return null
      }
      if (
        this.generatePowerOption &&
        this.generatePowerOption.list.length === 0
      ) {
        return null
      }
      const Tabtype = `${
        this.generatePowerTabs[this.generatePowerOpt].types[0]
      }`
      const tabTypes = this.generatePowerTabs[this.generatePowerOpt].types
      const generatePowerType = this.generatePowerOption.list[0].data.type
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      yAxis.push(makeChart.yAxis(Tabtype))
      xAxis.push(makeChart.xAxis(Tabtype))
      const dataset = []
      this.generatePowerChart.data.forEach((d, index) => {
        tabTypes.forEach((tab, tabIndex) => {
          // color.push(d.color)
          const label =
            this.generatePowerTabs[this.generatePowerOpt].labels[tabIndex]
          const key = `${tab}List`
          series.push({
            ...makeChart.series(
              this.generatePowerTabs[this.generatePowerOpt].types[0]
            ),
            name: `${d.name}${label ? '_' + label : ''}`,
            datasetIndex: dataset.length // 對應 dataset
          })
          dataset.push({
            dimensions: ['timestamp', 'value'],
            source: d.data ? d.data[key] || [] : []
          })
        })
      })
      const chartOpt = makeChart.data(Tabtype)
      return {
        ...chartOpt,
        // color,
        xAxis,
        yAxis,
        dataset,
        series
      }
    },
    ACChartData() {
      if (!this.ACChart.data) return null
      if (this.ACChart.data.length === 0) return null
      if (this.ACOption && this.ACOption.list.length === 0) return null
      const Tabtype = `${this.ACTabs[this.ACOpt].types[0]}`
      const tab = `${Tabtype}List`
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      yAxis.push(makeChart.yAxis(Tabtype))
      xAxis.push(makeChart.xAxis(Tabtype))
      const dataset = this.ACChart.data.map((d, index) => {
        // color.push(d.color)
        series.push({
          ...makeChart.series(Tabtype),
          name: d.name,
          datasetIndex: index
        })
        return {
          dimensions: ['timestamp', 'value'],
          source: d.data ? d.data[tab] || [] : []
        }
      })
      const chartOpt = makeChart.data(Tabtype)
      return {
        ...chartOpt,
        // color,
        xAxis,
        yAxis,
        dataset,
        series
      }
    },
    envChartData() {
      if (!this.envChart.data) return null
      if (this.envChart.data.length === 0) return null
      if (this.envOption && this.envOption.list.length === 0) return null
      const Tabtype = `${this.envTabs[this.envOpt].types[0]}`
      const tab = `${Tabtype}List`
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      yAxis.push(makeChart.yAxis(Tabtype))
      xAxis.push(makeChart.xAxis(Tabtype))
      const dataset = this.envChart.data.map((d, index) => {
        // color.push(d.color)
        series.push({
          ...makeChart.series(Tabtype),
          name: d.name,
          datasetIndex: index
        })
        return {
          dimensions: ['timestamp', 'value'],
          source: d.data ? d.data[tab] || [] : []
        }
      })
      const chartOpt = makeChart.data(this.envTabs[this.envOpt].types[0])
      return {
        ...chartOpt,
        // color,
        xAxis,
        yAxis,
        dataset,
        series
      }
    }
  },
  methods: {
    ...mapActions({
      getAllBuildings: 'building/getAllBuildings'
    }),
    handleTabChange(tabValue) {
      this.meterOption = null
      this.meterChart = {
        request: {},
        loading: false,
        data: null
      }
      this.meterOpt = tabValue
    },
    handleGenerateTabChange(tabValue) {
      this.generatePowerOption = null
      this.generatePowerChart = {
        request: {},
        loading: false,
        data: null
      }
      this.generatePowerOpt = tabValue
    },
    chartStatus(params, data) {
      if (
        !params ||
        params.list.length <= 0 ||
        !params.timestamp[0] ||
        !params.timestamp[1]
      ) {
        return {
          status: true,
          text: '請選擇目標及時間區間以進行查詢'
        }
      }
      if (!data.data) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      if (Array.isArray(data.data) && data.data.length === 0) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      return {
        status: false,
        text: '請選擇目標及時間區間以進行查詢'
      }
    },
    async getMeters() {
      this.schoolMeterData = []
      try {
        const { data } = await Meter.getSchoolMeters(this.tagId)
        this.schoolMeterData = data
      } catch (error) {
        console.error(error)
      }
    },
    async getMeterHistory() {
      const type = this.meterOption.list[0].data.type
      let Ids = this.meterOption.list
        .map((d, index) => {
          return {
            index,
            id: d.value,
            name: d.name
          }
        })
        .filter((d) => d.id)
      if (type === 'schoolMeter') {
        Ids = []
        this.meterOption.list.forEach((d, index) => {
          Ids.push({
            index,
            id: d.value,
            name: `${d.name}_${d.data.meter.name}`
          })
        })
        Ids = Ids.filter((d) => d.id)
      }
      if (type === 'classMeter') {
        Ids = []
        this.meterOption.list.forEach((d, index) => {
          d.deviceList.forEach((device) => {
            if (device.type === 'CLASSMETER') {
              Ids.push({
                index,
                id: device.id,
                name: `${d.name}_${device.name}`
              })
            }
          })
        })
        Ids = Ids.filter((d) => d.id)
      }
      if (!Ids || Ids.length <= 0) return

      this.meterChart.loading = true
      this.meterChart.request = {
        from: this.meterOption.timestamp[0],
        to: this.meterOption.timestamp[1],
        type,
        Ids
      }
      this.meterChart.data = []
      this.requests.meter = true
      for (let index = 0; index < Ids.length; index++) {
        const d = Ids[index]
        let data = null
        try {
          const exeFunction =
            type !== 'group' ? Meter.meterHistory : Meter.meterGroupHistory
          const res = await exeFunction({
            id: d.id,
            from: this.meterOption.timestamp[0],
            to: this.meterOption.timestamp[1],
            type: this.meterTabs[this.meterOpt].id
          })
          data = res.data
          if (data && data.length > 0) {
            data.forEach((MeterData) => {
              this.meterChart.data.push({
                color: this.chartColors[index],
                name: `${d.name}_${MeterData.name}`,
                data: MeterData
              })
            })
          } else {
            this.meterChart.data.push({
              color: this.chartColors[index],
              name: d.name,
              data
            })
          }
        } catch (error) {
          console.error(error)
          this.meterChart.data.push({
            color: this.chartColors[index],
            name: d.name,
            data: null
          })
        } finally {
          this.meterChart.loading = false
        }
      }
      this.requests.meter = false
    },
    async getGeneratePowerHistory() {
      const type = this.generatePowerOption.list[0].data.type
      let Ids = this.generatePowerOption.list
        .map((d, index) => {
          return {
            index,
            id: d.value,
            name: d.name
          }
        })
        .filter((d) => d.id)
      if (type === 'schoolMeter') {
        Ids = []
        this.generatePowerOption.list.forEach((d, index) => {
          Ids.push({
            index,
            id: d.value,
            name: `${d.name}_${d.data.meter.name}`
          })
        })
        Ids = Ids.filter((d) => d.id)
      }
      if (type === 'classMeter') {
        Ids = []
        this.generatePowerOption.list.forEach((d, index) => {
          d.deviceList.forEach((device) => {
            Ids.push({
              index,
              id: device.id,
              name: `${d.name}_${device.name}`
            })
          })
        })
        Ids = Ids.filter((d) => d.id)
      }
      if (!Ids || Ids.length <= 0) return

      this.generatePowerChart.loading = true
      this.generatePowerChart.request = {
        from: this.generatePowerOption.timestamp[0],
        to: this.generatePowerOption.timestamp[1],
        type,
        Ids
      }
      this.generatePowerChart.data = []
      this.requests.generatePower = true
      for (let index = 0; index < Ids.length; index++) {
        const d = Ids[index]
        let data = null
        try {
          const exeFunction =
            type !== 'group' ? Meter.meterHistory : Meter.meterGroupHistory
          const res = await exeFunction({
            id: d.id,
            from: this.generatePowerOption.timestamp[0],
            to: this.generatePowerOption.timestamp[1],
            type: this.generatePowerTabs[this.generatePowerOpt].id
          })
          data = res.data
          if (data && data.length > 0) {
            data.forEach((MeterData) => {
              this.generatePowerChart.data.push({
                color: this.chartColors[index],
                name: `${d.name}_${MeterData.name}`,
                data: MeterData
              })
            })
          } else {
            this.generatePowerChart.data.push({
              color: this.chartColors[index],
              name: d.name,
              data
            })
          }
        } catch (error) {
          console.error(error)
          this.generatePowerChart.data.push({
            color: this.chartColors[index],
            name: d.name,
            data: null
          })
        } finally {
          this.generatePowerChart.loading = false
        }
      }
      this.requests.generatePower = false
    },
    async getACHistory() {
      this.requests.ac = 0
      const classIds = this.ACOption.list
        .map((d, index) => {
          return {
            index,
            id: d.value,
            name: d.name
          }
        })
        .filter((d) => d.id)
      if (!classIds || classIds.length <= 0) return

      this.ACChart.loading = true
      this.ACChart.request = {
        from: this.ACOption.timestamp[0],
        to: this.ACOption.timestamp[1],
        classIds
      }
      this.ACChart.data = []
      this.requests.ac = true
      for (let index = 0; index < classIds.length; index++) {
        const d = classIds[index]
        try {
          const { data } = await AC.getClassHistory(d.id, {
            from: this.ACOption.timestamp[0],
            to: this.ACOption.timestamp[1]
          })
          if (data && data.length > 0) {
            data.forEach((ACData) => {
              this.ACChart.data.push({
                color: this.chartColors[index],
                name: `${d.name}_${ACData.name}`,
                data: ACData
              })
            })
          } else {
            this.ACChart.data.push({
              color: this.chartColors[index],
              name: d.name,
              data: null
            })
          }
        } catch (error) {
          this.ACChart.data.push({
            color: this.chartColors[index],
            name: d.name,
            data: null
          })
          console.error(error)
        } finally {
          this.ACChart.loading = false
        }
      }
      this.requests.ac = false
    },
    async getEnvHistory() {
      this.requests.env = 0
      const classIds = this.envOption.list
        .map((d, index) => {
          return {
            index,
            id: d.value,
            name: d.name
          }
        })
        .filter((d) => d.id)
      if (!classIds || classIds.length <= 0) return
      this.envChart.loading = true
      this.envChart.request = {
        from: this.envOption.timestamp[0],
        to: this.envOption.timestamp[1],
        classIds
      }
      this.envChart.data = []
      this.requests.env = true
      for (let index = 0; index < classIds.length; index++) {
        const d = classIds[index]
        try {
          let data = null
          // envOpt 0: 班級室內溫度 1:室外溫度 2:室外濕度 3:室外風速
          if (this.envOpt === 0) {
            // 班級室內溫度
            const res = await AC.getClassEnvHistory(d.id, {
              from: this.envOption.timestamp[0],
              to: this.envOption.timestamp[1]
            })
            data = res.data
          } else if (this.envOpt === 1) {
            // 室外溫度
            const res = await Environment.getSchoolClimatology(
              d.id,
              'temperature',
              this.envOption.timestamp[0],
              this.envOption.timestamp[1]
            )
            data = {
              temperatureList: res.data
            }
          } else if (this.envOpt === 2) {
            // 室外濕度
            const res = await Environment.getSchoolClimatology(
              d.id,
              'humidity',
              this.envOption.timestamp[0],
              this.envOption.timestamp[1]
            )
            data = {
              humidityList: res.data
            }
          } else if (this.envOpt === 3) {
            // 室外風速
            const res = await Environment.getSchoolClimatology(
              d.id,
              'windspeed',
              this.envOption.timestamp[0],
              this.envOption.timestamp[1]
            )
            data = {
              windSpeedList: res.data.map((item) => {
                if (item.value === '超過12' || item.value >= 13) {
                  item.value = 13
                }
                return item
              })
            }
          }
          this.envChart.data.push({
            color: this.chartColors[index],
            name: d.name,
            data
          })
        } catch (error) {
          this.envChart.data.push({
            color: this.chartColors[index],
            name: d.name,
            data: null
          })
          console.error(error)
        } finally {
          this.envChart.loading = false
        }
      }
      this.requests.env = false
    }
  },
  mounted() {
    this.getMeters()
    if (!this.buildings) {
      this.getAllBuildings(this.tagId)
    }
  }
}
</script>

<style></style>
